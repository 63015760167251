import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { ChevronDown, ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
// import { connect } from "react-redux"
import { history } from "../../../../history";
// import navigationConfig from "../../../../configs/horizontalMenuConfig";
import { useStore } from "../../../../stores/index";
import { observer } from "mobx-react-lite";
import { contains } from "ramda";

import {horizontalMenuConfig as navigationConfig } from '../../../../configs/horizontalMenuConfig';

const HorizontalSidebar: React.FC = observer((props: any) => {
  // const HorizontalSidebar: React.FC = observer((props: any) => {
  // constructor(props) {
  //   super(props)

  // this.state = {
  //   activeParents: [],
  //   openDropdown: [],
  //   dropdownHeight: "auto",
  //   itemHover: null,
  //   parentHover: null,
  //   activeChildUrl: null
  // }
  const {
    accountStore: {
      currentUser,
      roleRights,
      loading: {
        currentUser: { itemLoading },
        right : {listLoading}
      },
    },
  } = useStore();

  const accessAllowed = (custAccess: string[], user: AppUser): boolean => {
    if (!user) return false;
    const hasCutomerID: boolean = user.customerId !== "" && user.customerId;
    if (hasCutomerID) return contains("customer", custAccess);
    return contains("productOwner", custAccess);
  };

  const [activeParents, setActiveParents] = useState<any>([]);
  const [stateOpenDropdown, setStateOpenDropdown] = useState<any>([]);
  const [dropdownHeight, setDropdownHeight] = useState<any>("auto");
  const [itemHover, setItemHover] = useState<any>(null);
  const [parentHover, setParentHover] = useState<any>(null);
  const [activeChildUrl, setActiveChildUrl] = useState<any>(null);
  const [openLeft, setOpenLeft] = useState<any>("");

  // }
  const redirectUnauthorized = () => {
    history.push("misc/not-authorized");
  };

  let activeFlag: any = false;
  let parentItems: any = [];
  let activeParentItems: any = [];

  const openDropdown = (id) => {
    let arr = stateOpenDropdown;
    if (!arr.includes(id)) arr.push(id);
    // this.setState({
    setStateOpenDropdown(arr);
    // })
  };

  const closeDropdown = (id) => {
    let arr = stateOpenDropdown;
    arr.splice(arr.indexOf(id), 1);
    // this.setState({
    setStateOpenDropdown(arr);
    // })
  };

  const handleItemHover = (id) => {
    // this.setState({
    setItemHover(id);
    // })
  };

  const handleParentHover = (id) => {
    // this.setState({
    setParentHover(id);
    // })
  };

  useEffect(() => {
    handleActiveParent(activeParentItems);
  }, []);

  // componentDidMount() {
  //   handleActiveParent(this.activeParentItems)
  // }

  // componentDidUpdate(prevProps) {
  //   if (props.activePath !== prevProps.activePath) {
  //     setState({ openDropdown: [], parentHover: null })
  //     handleActiveParent(this.activeParentItems)
  //   }
  // }

  const updateParentItems = (id: any, mainParent = false) => {
    if (mainParent === true) {
      parentItems = [];
    }
    if (!parentItems.includes(id)) {
      parentItems.push(id);
    }
    if (activeFlag === true) {
      activeParentItems = parentItems;
      parentItems = [];
      activeFlag = false;
    }
  };

  const handleActiveParent = (arr) => {
    // this.setState({
    setActiveParents(arr);
    // })
    activeFlag = false;
  };

  const renderSubMenu = (submenu, id) => {
    return (
      <DropdownMenu
        tag="ul"
        className="mt-50"
        onMouseEnter={(e) => e.preventDefault()}
        modifiers={{
          setMaxHeight: {
            enabled: true,
            fn: (data: any) => {
              let pageHeight = window.innerHeight,
                ddTop = data.instance.reference.getBoundingClientRect().top,
                ddHeight = data.popper.height,
                maxHeight,
                stylesObj;

              if (pageHeight - ddTop - ddHeight - 28 < 1) {
                maxHeight = pageHeight - ddTop - 25;
                stylesObj = {
                  maxHeight: maxHeight,
                  overflowY: "auto",
                };
              }

              return {
                ...data,

                styles: {
                  ...stylesObj,
                },
              };
            },
          },
        }}
      >
        {submenu
          .filter((x) => accessAllowed(x.customAccess, currentUser))
          .map((child) => {
            const CustomAnchorTag = child.type === "external-link" ? `a` : Link;
            if (child.navLink && child.navLink === props.activePath) {
              activeFlag = true;
              updateParentItems(id);
            }

            let renderChildItems = (
              <React.Fragment key={child.id}>
                <li
                  className={classnames({
                    active: activeParents.includes(child.id),
                  })}
                >
                  <DropdownItem
                    className={classnames("w-100", {
                      hover: itemHover === child.id,
                      "has-sub": child.children,
                      active:
                        (child.parentOf &&
                          child.parentOf.includes(props.activePath)) ||
                        (child.navLink && child.navLink === props.activePath),
                      "has-active-child": stateOpenDropdown.includes(child.id),
                      disabled: child.disabled,
                    })}
                    tag={child.navLink ? CustomAnchorTag : "div"}
                    to={
                      child.filterBase
                        ? child.filterBase
                        : child.navLink && child.type === "item"
                        ? child.navLink
                        : "#"
                    }
                    href={
                      child.type === "external-link" ? child.navLink : undefined
                    }
                    target={child.newTab ? "_blank" : undefined}
                    onMouseEnter={() => handleItemHover(child.id)}
                    onMouseLeave={() => handleItemHover(null)}
                  >
                    {child.children ? (
                      <Dropdown
                        className={classnames("sub-menu w-100", {})}
                        isOpen={stateOpenDropdown.includes(child.id)}
                        direction={openLeft ? "left" : "right"}
                        toggle={() => true}
                        onMouseEnter={() => openDropdown(child.id)}
                        onMouseLeave={() => closeDropdown(child.id)}
                      >
                        <DropdownToggle
                          className="d-flex justify-content-between align-items-center item-content"
                          tag={"div"}
                          onClick={() => closeDropdown(child.id)}
                        >
                          <div className="dropdown-toggle-sub text-truncate">
                            <span className="menu-icon align-bottom mr-1">
                              {child.icon}
                            </span>
                            <span className="menu-title align-middle">
                              {child.title}
                            </span>
                          </div>
                          <ChevronRight
                            className="has-sub-arrow align-middle ml-50"
                            size={15}
                          />
                        </DropdownToggle>
                        {child.children
                          ? renderSubMenu(child.children, child.id)
                          : null}
                      </Dropdown>
                    ) : (
                      <div className="item-content">
                        <span className="menu-icon align-top mr-1">
                          {child.icon}
                        </span>
                        <span className="menu-title align-middle">
                          {child.title}
                        </span>
                      </div>
                    )}
                  </DropdownItem>
                </li>
              </React.Fragment>
            );

            if (
              child.type === "external-link" ||
              (child.type === "item" &&
                child.permissions &&
                child.permissions.includes(props.currentUser)) ||
              child.type === "dropdown" ||
              child.permissions === undefined
            ) {
              return renderChildItems;
            } else if (
              child.navLink === props.activePath &&
              !child.permissions.includes(props.currentUser)
            ) {
              return redirectUnauthorized();
            } else {
              return null;
            }
          })}
      </DropdownMenu>
    );
  };

  let menuDrodpown: any = "";

  const renderDropdown = (arr) => {
    return arr.map((item) => {
      if (
        item.type === "item" &&
        item.navLink &&
        item.navLink === props.activePath
      ) {
        activeFlag = true;
        updateParentItems(item.id, true);
      }
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;

      const hasRight =
      !item.rightId ? true : roleRights.findIndex((x) => x.id.toLowerCase() === item.rightId.toLowerCase()) !== -1;
      
      return (
        <>
          {hasRight && (
            <li
              className={classnames("nav-item", {
                active: item.navLink === props.activePath,
                hover: parentHover === item.id,
              })}
              key={item.id}
              ref={(el) => (menuDrodpown = el)}
              style={
                item.children && item.id === "configure"
                  ? { position: "absolute", right: "20px", top: "0px" }
                  : {}
              }
            >
              <div
                className={classnames("nav-item-wrapper cursor-pointer", {
                  "single-item": item.type === "item",
                })}
                onMouseEnter={() => {
                  openDropdown(item.id);
                  handleParentHover(item.id);
                }}
                onMouseLeave={() => {
                  closeDropdown(item.id);
                  handleParentHover(null);
                }}
              >
                {item.children ? (
                  <Dropdown
                    isOpen={stateOpenDropdown.includes(item.id)}
                    className="nav-link"
                    toggle={() => openDropdown(item.id)}
                  >
                    <DropdownToggle
                      className="d-flex align-items-center"
                      tag="div"
                    >
                      <div className="dropdown-text">
                        <span className="menu-icon align-middle mr-75">
                          {item.icon}
                        </span>
                        <span className="menu-title align-middle">
                          {item.title}
                        </span>
                      </div>
                      <ChevronDown className="ml-50 align-bottom" size={15} />
                    </DropdownToggle>

                    {updateParentItems(item.id, true)}
                    {item.children
                      ? renderSubMenu(item.children, item.id)
                      : null}
                  </Dropdown>
                ) : (
                  <CustomAnchorTag
                    className={classnames({
                      "nav-link": item.type === "item",
                      hover: parentHover === item.id,
                    })}
                    to={
                      item.filterBase
                        ? item.filterBase
                        : item.navLink && item.type === "item"
                        ? item.navLink
                        : "#"
                    }
                    href={
                      item.type === "external-link" ? item.navLink : undefined
                    }
                    target={item.newTab ? "_blank" : undefined}
                  >
                    <span className="menu-icon align-middle mr-75">
                      {item.icon}
                    </span>
                    <span className="menu-title align-middle">
                      {item.title}
                    </span>
                  </CustomAnchorTag>
                )}
              </div>
            </li>
          )}
        </>
      );
    });
  };

  // render() {
  return (
    <div className="horizontal-menu-wrapper">
      <div
        className={classnames(
          "header-navbar navbar-expand-sm navbar navbar-horizontal navbar-shadow",
          {
            "navbar-static": props.navbarType === "static",
            "fixed-top": props.navbarType === "sticky",
            "floating-nav":
              props.navbarType === "floating" ||
              !["static", "sticky", "floating"].includes(props.navbarType),
          }
        )}
      >
        <div className="navbar-container main-menu-content">
          <ul className="nav navbar-nav" id="main-menu-navigation">
            {(itemLoading || listLoading )  && (
              <li
                style={{ display: "block", textAlign: "center", width: "100%" }}
              >
                <Spinner size="md" color="primary" />
              </li>
            )}
            {!(itemLoading || listLoading ) &&
              renderDropdown(
                navigationConfig.filter((x) =>
                  accessAllowed(x.customAccess, currentUser!)
                )
              )}
          </ul>
        </div>
      </div>
    </div>
  );
});
// }
// const mapStateToProps = state => {
//   return {
//     currentUser: state.auth.login.userRole
//   }
// }
// export default connect(mapStateToProps)(HorizontalSidebar)
export default HorizontalSidebar;
