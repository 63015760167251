import React from "react";
import * as Icon from "react-feather";
import { EnumAppRights } from "utility/Common";
import assetImage from "../assets/img/asset.png";
import equipmentImage from "../assets/img/Equipment.png";
import teams from "../assets/img/navigation-icons/teams.svg";
import branch from "../assets/img/navigation-icons/branch.svg";
import section from "../assets/img/navigation-icons/depts.svg";
import subsection from "../assets/img/navigation-icons/lines.svg";
import rolesRight from "../assets/img/navigation-icons/roles.svg";
import shifts from "../assets/img/navigation-icons/shifts.svg";
import userBi from "../assets/img/navigation-icons/user-bi.svg";
import equipmentBi from "../assets/img/navigation-icons/equipment-bi.svg";
import exploreBi from "../assets/img/navigation-icons/explore-bi.svg";
import reportsBi from "../assets/img/navigation-icons/reports-bi.svg";
import Edocs from "../assets/img/navigation-icons/e-docs.svg";



export const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner", "customer"],
    navLink: "/",
    rightId: EnumAppRights.Dashboard,
  },
  {
    id: "bi",
    title: "BI",
    type: "dropdown",
    icon: <img src={reportsBi} alt="BI" width={20} height={20}/>,
    customAccess: ["customer"],
    children: [
      {
        id: "ReportsBI",
        title: "Reports BI",
        type: "item",
        icon: <img src={reportsBi} alt="Reports BI" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/reportsbi",
        rightId: EnumAppRights.Dashboard,
      },
      {
        id: "UserBI",
        title: "User BI",
        type: "item",
        icon: <img src={userBi} alt="User BI" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/userbi",
        rightId: EnumAppRights.UserBI,
      },
      {
        id: "EquipmentBi",
        title: "Equipment BI",
        type: "item",
        icon: <img src={equipmentBi} alt="Equipment BI" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/equipmentbi",
        rightId: EnumAppRights.EquipmentBI,
      },
      {
        id: "ExploreBi",
        title: "Explore BI",
        type: "item",
        icon: <img src={exploreBi} alt="Explore BI" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/explorebi",
        rightId: EnumAppRights.ExploreBI,
      },
    ],
  },
  {
    id: "packages",
    title: "Package",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/packages",
  },
  {
    id: "customers",
    title: "Customer",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/customers",
  },
  {
    id: "subscriptions",
    title: "Subscriptions",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/subscriptions",
  },
  {
    id: "alarmequipments",
    title: "Alarmed Equipments",
    type: "item",
    icon: <Icon.AlertTriangle size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["customer"],
    navLink: "/alarmequipments",
    rightId: EnumAppRights.AlarmedEquipmentsManagement,
  },
  {
    id: "FixRequest",
    title: "Fix Request",
    type: "item",
    icon: <Icon.AlertOctagon size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["customer"],
    navLink: "/fixrequest",
    rightId: EnumAppRights.FixRequestsManagement,
  },
  {
    id: "scheduledroutes",
    title: "Scheduled Routes",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["customer"],
    navLink: "/scheduledroutes",
    rightId: EnumAppRights.ScheduledRoutesManagement,
  },
  {
    id: "teams",
    title: "Teams",
    type: "item",
    icon: <img src={teams} alt="asset" />,
    customAccess: ["customer"],
    navLink: "/teams",
  },
  {
    id: "reports",
    title: "Reports",
    type: "dropdown",
    icon: <Icon.Settings size={20} />,
    customAccess: ["customer"],
    children: [
      {
        id: "ActionStatus",
        title: "Action Status",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/ActionStatus",
        rightId: EnumAppRights.DailyReport,
      },
      {
        id: "Daily Report",
        title: "Daily Report",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/dailyreport",
        rightId: EnumAppRights.DailyReport,
      },
      {
        id: "Ip Type Anomaly",
        title: "Anomaly Insights",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/AnomalyInsights",
        rightId: EnumAppRights.IpTypeAnomalyReport,
      },
      {
        id: "EquipmentHealthSurvey",
        title: "Equipment Health Survey",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/EquipmentHealthSurvey",
        rightId: EnumAppRights.EquipmentHealthReport,
      },
      {
        id: "EquipmentInsight",
        title: "Equipment Insights",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/EquipmentInsights",
        rightId: EnumAppRights.LastInspectionIpStatusReport,
      },
      {
        id: "IpUtilization",
        title: "Ip Utilization",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/iputilization",
        rightId: EnumAppRights.IpUutilization,
      },
      {
        id: "AlarmedEquipmentHistory",
        title: "Alarmed Equipment History",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/alarmedequipmenthistory",
        rightId: EnumAppRights.AlarmedEquipmentsManagement,
      },
      {
        id: "routeschedulesummary",
        title: "Route Schedule Summary",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["customer"],
        navLink: "/routeschedulesummary",
        rightId: EnumAppRights.RouteScheduleSummary,
      },
    ],
  },
  {
    id: "configure",
    title: "Configure",
    type: "dropdown",
    icon: <Icon.Settings size={20} />,
    customAccess: ["productOwner", "customer"],
    children: [
      {
        id: "users",
        title: "User",
        type: "item",
        icon: <Icon.Users size={20} />,
        customAccess: ["productOwner", "customer"],
        navLink: "/users",
      },
      {
        id: "rolesrights",
        title: "Roles & Rights",
        type: "item",
        icon: <img src={rolesRight} alt="Roles & Rights"/>,
        customAccess: ["customer"],
        navLink: "/rolesrights",
        rightId: EnumAppRights.RolesRightsManagement,
      },
      {
        id: "branches",
        title: "Branch",
        type: "item",
        icon: <img src={branch} alt="branch" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/branches",
        rightId: EnumAppRights.BranchManagement,
      },
      {
        id: "sections",
        title: "Section",
        type: "item",
        icon: <img src={section} alt="section"/>,
        customAccess: ["customer"],
        navLink: "/sections",
        rightId: EnumAppRights.SectionsManagement,
      },
      {
        id: "subsections",
        title: "Sub Section",
        type: "item",
        icon: <img src={subsection} alt="Sub Section"/>,
        customAccess: ["customer"],
        navLink: "/subsections",
        rightId: EnumAppRights.SubSectionsManagement,
      },
      {
        id: "assets",
        title: "Asset",
        type: "item",
        icon: <img src={equipmentImage} alt="asset" style={{ height: "20px" }} />,
        customAccess: ["customer"],
        navLink: "/assets",
        rightId: EnumAppRights.AssetManagement,
      },
      {
        id: "equipment",
        title: "Equipment",
        type: "item",
        icon: <img src={assetImage} alt="asset" width={18} height={20} />,
        customAccess: ["customer"],
        navLink: "/equipments",
        rightId: EnumAppRights.EquipmentManagement,
      },
      {
        id: "inspectionpoint",
        title: "Inspection Point",
        type: "item",
        icon: <Icon.CheckCircle size={20} />,
        customAccess: ["customer"],
        navLink: "/inspectionpoints",
        rightId: EnumAppRights.InspectionPointManagement,
      },
      {
        id: "routes",
        title: "Routes",
        type: "item",
        icon: <Icon.Share2 size={20} />,
        customAccess: ["customer"],
        navLink: "/routes",
        rightId: EnumAppRights.RoutesManagement,
      },
      {
        id: "edocs",
        title: "eDocs",
        type: "item",
        icon: <img src={Edocs} alt="E Docs" />,
        customAccess: ["productOwner", "customer"],
        navLink: "/edocs",
        rightId: EnumAppRights.EDocsManagement,
      },
      {
        id: "shifts",
        title: "Shifts",
        type: "item",
        icon: <img src={shifts} alt="shifts"/>,
        customAccess: ["customer"],
        navLink: "/shifts",
        rightId: EnumAppRights.ShiftsManagment,
      },
      {
        id: "templates",
        title: "Templates",
        type: "item",
        icon: <Icon.BarChart size={20} />,
        customAccess: ["productOwner", "customer"],
        navLink: "/templates",
        rightId: EnumAppRights.TemplateManagement,
      },
      {
        id: "templateRecipe",
        title: "Template Recipe",
        type: "item",
        icon: <Icon.FilePlus size={20} />,
        customAccess: ["productOwner", "customer"],
        navLink: "/recipies",
        rightId: EnumAppRights.BranchManagement,
      },
    ],
  },
];


